import React, { createContext, useState, useEffect } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (productId, price, quantity, color) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(
        (item) => item.id === productId && item.color === color
      );

      if (existingItem) {
        return prevItems.map((item) =>
          item.id === productId && item.color === color
            ? { ...item, quantity: item.quantity + quantity }
            : item
        );
      } else {
        return [...prevItems, { id: productId, price, quantity, color }];
      }
    });
  };

  const removeFromCart = (productId, color) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => item.id !== productId || item.color !== color)
    );
  };

  const clearCart = () => {
    setCartItems([]);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
