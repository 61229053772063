import React, { useState, useContext } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/menu.css";
import { Link } from "react-router-dom";
import productData from "../Datas/productData";

function Menu() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="sub_main">
        <img
          alt=""
          src="https://www.hoyu-direct.com/Contents/images/top/slider_naturain.jpg"
        />
      </div>
      <div className="first_greeting">
        <article>
          <p>創業100年を超える 頭髪化粧品ひとすじのメーカー</p>
          <h2>
            <font _mstmutation="1">初めまして！です。</font>
            <img
              src="https://www.hoyu-direct.com/Contents/images/top/logo_grey.png"
              width="138"
              height="74"
              alt="hoyu"
              _mstalt="50375"
            />
          </h2>
        </article>
      </div>
      <section id="sec_item" data-bdf="scrl,on" className="onoff bg_gray on">
        <h2>ナチュラインシリーズ</h2>
        <ul>
          {productData.map((menu, index) => (
            <li key={index}>
              <Link to={`/shopList/${menu.id}`} onClick={handleNavigate}>
                <div className="menu_image">
                  <img alt="" src={menu.images[0]} />
                  <p>{menu.name}</p>
                  <h3>{menu.menuTxt}</h3>
                </div>
              </Link>
            </li>
          ))}
        </ul>
        <p className="hair_img">
          <img
            alt=""
            src="https://www.hoyu-direct.com/Contents/images/top/bnr_product_guide.png"
          />
        </p>
      </section>
      <Footer />
    </div>
  );
}

export default Menu;
