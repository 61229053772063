import React, { useState, useEffect } from "react";
import "../css/checkout.css";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const CheckOut = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    lastName: "",
    firstName: "",
    lastNameKana: "",
    firstNameKana: "",
    birthYear: "",
    birthMonth: "",
    birthDay: "",
    gender: "",
    email: "",
    zipCode: "",
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    phone1: "",
    phone2: "",
    phone3: "",
    howHeard: "未選択",
    detailInfo: "",
  });
  const [isValid, setIsValid] = useState(true); // 初始假设表单是有效的

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkFields = () => {
    const requiredFields = document.querySelectorAll(".necessary");
    let allFilled = true;

    requiredFields.forEach((field) => {
      const inputs = field
        .closest("tr")
        .querySelectorAll("input, select, textarea");
      let allInputsFilled = true;

      inputs.forEach((input) => {
        if (!input.value.trim()) {
          input.style.borderColor = "red";
          allInputsFilled = false;
        } else {
          input.style.borderColor = "";
        }
      });

      if (!allInputsFilled) {
        allFilled = false;
      }
    });

    setIsValid(allFilled);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    checkFields();
    if (isValid) {
      navigate("/paysuccess");
    } else {
      alert("全ての必須項目を入力してください");
    }
  };

  useEffect(() => {
    checkFields();
  }, [form]);

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header />
      <div className="ctl00_ContentPlaceHolder1_upUpdatePanel">
        <div className="contents cart cart_top">
          <ul className="bread_link">
            <li>
              <Link to="/">TOP</Link>
            </li>
            <li>お客様情報</li>
          </ul>
          <h2 className="section_ttl">
            <font _mstmutation="1">PROFILE INFORMATION</font>
            <span>ユーザー情報</span>
          </h2>
          <div className="productList">
            <div className="list" style={{ border: "none", width: "56%" }}>
              <h3 className="form_ttl">お客様情報</h3>
              <article className="input_area">
                <div id="ctl00_ContentPlaceHolder1_upUpdatePanel">
                  <table className="input-box">
                    <tbody>
                      {/* 姓名 */}
                      <tr>
                        <th>
                          氏名<span className="necessary">必須</span>
                        </th>
                        <td>
                          <input
                            type="text"
                            name="lastName"
                            placeholder="姓"
                            value={form.lastName}
                            onChange={handleChange}
                            maxLength="10"
                          />
                          <input
                            type="text"
                            name="firstName"
                            placeholder="名"
                            value={form.firstName}
                            onChange={handleChange}
                            maxLength="10"
                          />
                        </td>
                      </tr>
                      {/* 姓名(假名) */}
                      <tr>
                        <th>
                          氏名(かな)<span className="necessary">必須</span>
                        </th>
                        <td>
                          <input
                            type="text"
                            name="lastNameKana"
                            placeholder="ひらがな姓"
                            value={form.lastNameKana}
                            onChange={handleChange}
                            maxLength="20"
                          />
                          <input
                            type="text"
                            name="firstNameKana"
                            placeholder="ひらがな名"
                            value={form.firstNameKana}
                            onChange={handleChange}
                            maxLength="20"
                          />
                        </td>
                      </tr>
                      {/* 生年月日 */}
                      <tr>
                        <th>生年月日</th>
                        <td>
                          <select
                            name="birthYear"
                            value={form.birthYear}
                            onChange={handleChange}
                          >
                            <option value="">----</option>
                            <option value="2024">2024</option>
                          </select>
                          <font>年</font>
                          <select
                            name="birthMonth"
                            value={form.birthMonth}
                            onChange={handleChange}
                          >
                            <option value="">--</option>
                            <option value="1">1</option>
                          </select>
                          <font>月</font>
                          <select
                            name="birthDay"
                            value={form.birthDay}
                            onChange={handleChange}
                          >
                            <option value="">--</option>
                            <option value="1">1</option>
                            {/* 其他日期选项 */}
                          </select>
                          <font>日</font>
                        </td>
                      </tr>
                      {/* 性别 */}
                      <tr>
                        <th>性別</th>
                        <td>
                          <span>
                            <input
                              type="radio"
                              id="male"
                              name="gender"
                              value="MALE"
                              onChange={handleChange}
                            />
                            <label htmlFor="male">男</label>
                            <input
                              type="radio"
                              id="female"
                              name="gender"
                              value="FEMALE"
                              onChange={handleChange}
                            />
                            <label htmlFor="female">女</label>
                            <input
                              type="radio"
                              id="unknown"
                              name="gender"
                              value="UNKNOWN"
                              onChange={handleChange}
                            />
                            <label htmlFor="unknown">未回答</label>
                          </span>
                        </td>
                      </tr>
                      {/* 邮件地址 */}
                      <tr>
                        <th>
                          メールアドレス<span className="necessary">必須</span>
                        </th>
                        <td>
                          <input
                            type="email"
                            name="email"
                            placeholder="letter@zuizuify.shop"
                            value={form.email}
                            onChange={handleChange}
                            maxLength="256"
                            className="address_other_input1"
                          />
                        </td>
                      </tr>
                      {/* 邮政编码 */}
                      <tr>
                        <th>
                          郵便番号<span className="necessary">必須</span>
                        </th>
                        <td>
                          <input
                            type="text"
                            name="zipCode"
                            placeholder="000-0000"
                            value={form.zipCode}
                            onChange={handleChange}
                            maxLength="8"
                          />
                        </td>
                      </tr>
                      {/* 地址 */}
                      <tr>
                        <th>
                          住所<span className="necessary">必須</span>
                        </th>
                        <td>
                          <select
                            name="address1"
                            value={form.address1}
                            onChange={handleChange}
                          >
                            <option value="">----</option>
                            <option value="Hokkaido">北海道</option>
                            {/* 其他都道府县选项 */}
                          </select>
                          <br />
                          <input
                            type="text"
                            name="address2"
                            placeholder="市区町村"
                            value={form.address2}
                            onChange={handleChange}
                            maxLength="40"
                            className="address_other_input"
                          />
                          <br />
                          <input
                            type="text"
                            name="address3"
                            placeholder="番地"
                            value={form.address3}
                            onChange={handleChange}
                            maxLength="40"
                            className="address_other_input"
                          />
                          <br />
                          <input
                            type="text"
                            name="address4"
                            placeholder="建物名"
                            value={form.address4}
                            onChange={handleChange}
                            maxLength="40"
                            className="address_other_input address_other_input1"
                          />
                        </td>
                      </tr>
                      {/* 电话番号 */}
                      <tr>
                        <th>
                          電話番号<span className="necessary">必須</span>
                        </th>
                        <td>
                          <input
                            type="text"
                            name="phone1"
                            placeholder="000"
                            value={form.phone1}
                            onChange={handleChange}
                            maxLength="6"
                          />
                          -
                          <input
                            type="text"
                            name="phone2"
                            placeholder="0000"
                            value={form.phone2}
                            onChange={handleChange}
                            maxLength="4"
                          />
                          -
                          <input
                            type="text"
                            name="phone3"
                            placeholder="0000"
                            value={form.phone3}
                            onChange={handleChange}
                            maxLength="4"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="enquete_all">
                    <div className="enquete">
                      <h4>アンケートにご協力ください</h4>
                      <dl>
                        <dt>
                          当サイト（ホーユーダイレクトオンライン）に訪問されたきっかけは何ですか？
                        </dt>
                        <dd>
                          <div>
                            <select
                              name="howHeard"
                              value={form.howHeard}
                              onChange={handleChange}
                              id="howHeardSelect"
                            >
                              <option value="未選択">未選択</option>
                              <option value="01">1.テレビ</option>
                              <option value="02">2.雑誌</option>
                              <option value="03">3.ホーユーの企業サイト</option>
                              <option value="04">4.LICOLO</option>
                              <option value="05">5.商品付属のケア剤</option>
                              <option value="06">6.web広告</option>
                              <option value="07">7.口コミサイト</option>
                              <option value="08">8.ブログ</option>
                              <option value="09">9.Youtube</option>
                              <option value="10">10.Instagram</option>
                              <option value="11">11.X</option>
                              <option value="12">12.TikTok</option>
                              <option value="13">13.知人・友人紹介</option>
                              <option value="14">14.美容師からの推奨</option>
                              <option value="99">15.その他</option>
                            </select>
                          </div>
                        </dd>
                        <dt>
                          参考にしたYoutube等SNSのアカウント名（インフルエンサー名）や、「15.その他」を選択された方は詳細をご記入ください。
                        </dt>
                        <dd>
                          <textarea
                            name="detailInfo"
                            placeholder="詳細をご記入ください"
                            value={form.detailInfo}
                            onChange={handleChange}
                            rows="4"
                            cols="20"
                          />
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </article>

              <div className="btn_area move">
                <ul>
                  <li className="back">
                    <Link to="/cartpage" onClick={toTop}>
                      前の画面に戻る
                    </Link>
                  </li>
                  <li>
                    <Link to="" onClick={handleSubmit} disabled={!isValid}>
                      確認する
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CheckOut;
