import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/header.css";

function Header() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="header">
        <div className="header__inner__left">
          <img
            alt=""
            src="https://www.hoyu.co.jp/assets/img/common/icon_company_logo.png"
          />
          <div className="header__inner">
            <div className="wrp-navi">
              <Link to="/" onClick={toTop}>
                ホームページ
              </Link>
              <Link to="/menu" onClick={toTop}>
                しょうひん
              </Link>
              <Link to="/contact" onClick={toTop}>
                お問い合わせ
              </Link>
              <Link to="/privacy" onClick={toTop}>
                個人情報保護方針
              </Link>
              <Link to="/cartpage" onClick={toTop}>
                <img
                  style={{ width: "30px", height: "30px" }}
                  alt=""
                  src="/head/cart.png"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
