import React, { useContext } from "react";
import { CartContext } from "../Component/CartContext";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/cartpage.css";
import { useNavigate, Link } from "react-router-dom";

const CartPage = () => {
  const { cartItems, removeFromCart, addToCart } = useContext(CartContext);
  const navigate = useNavigate();

  const getProductById = (id) => {
    return productData.find((product) => Number(product.id) === Number(id));
  };

  const toCheckOut = () => {
    if (cartItems.length === 0) {
      alert("ショッピングカートが空です!");
      return;
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/checkout");
  };

  const handleQuantityChange = (item, change) => {
    const newQuantity = item.quantity + change;
    if (newQuantity > 0) {
      addToCart(item.id, item.price, change, item.color);
    }
  };

  const calculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      const product = getProductById(item.id);
      if (product) {
        total += item.price * item.quantity;
      }
    });
    return total.toLocaleString();
  };

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="main-container">
        <Header />
        <div className="ctl00_ContentPlaceHolder1_upUpdatePanel">
          <div className="contents cart cart_top">
            <ul className="bread_link">
              <li>
                <Link to="/">TOP</Link>
              </li>
              <li>ショッピングカート</li>
            </ul>
            <h2 className="section_ttl">
              <font _mstmutation="1">SHOPPING CART</font>
              <span>ショッピングカート</span>
            </h2>
            <div className="productList">
              <div className="list">
                {cartItems.length === 0 ? (
                  <h3 style={{ color: "black", textAlign: "center" }}>
                    Your Cart is Empty!
                  </h3>
                ) : (
                  <div>
                    {cartItems.map((item, index) => {
                      const product = getProductById(item.id);
                      return (
                        <div className="product" key={index}>
                          <div className="item_detail clearfix">
                            <dl className="name">
                              <dt>
                                <img
                                  id="imgProductImage"
                                  src={
                                    product.images[1].picture ||
                                    product.images[1]
                                  }
                                  alt={product.name}
                                  className=""
                                />
                              </dt>
                              <dd>
                                <p className="icon">
                                  <span>{product.name}</span>
                                  <br />
                                  {item.color && (
                                    <span style={{ fontWeight: "100" }}>
                                      色: {item.color.color || item.color}
                                    </span>
                                  )}
                                </p>
                              </dd>
                            </dl>
                            <p className="quantity">
                              <span>
                                <input
                                  type="button"
                                  value="－"
                                  id="minus1"
                                  className="quantityDown"
                                  _mstvalue="5941663"
                                  onClick={() => handleQuantityChange(item, -1)}
                                />
                                <input
                                  name="matome"
                                  type="text"
                                  min="0"
                                  max="10"
                                  id="nthm3_1"
                                  pid_teika="HM-MA-teika"
                                  vid_teika="RBR"
                                  value={item.quantity}
                                  readOnly
                                  className="quantityInput"
                                />
                                <input
                                  type="button"
                                  value="＋"
                                  id="add1"
                                  className="quantityUp"
                                  onClick={() => handleQuantityChange(item, 1)}
                                  _mstvalue="3913"
                                />
                              </span>
                            </p>
                            <p className="subtotal">
                              {(item.price * item.quantity).toLocaleString()}円
                              <span className="taxin">(税込)</span>
                            </p>
                            <p className="delete">
                              <Link
                                to=""
                                onClick={() =>
                                  removeFromCart(item.id, item.color)
                                }
                              >
                                ×
                              </Link>
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {cartItems.length > 0 && (
                  <div className="cartOrder">
                    <div className="subcartOrder clearfix">
                      <div className="couponBox"></div>
                      <div className="priceList">
                        <div>
                          <dl className="fee_table bgc">
                            <dt>小計(税込)</dt>
                            <dd>
                              {calculateTotal()}円
                              <span className="taxin">(税込)</span>
                            </dd>
                            <span>
                              <dt>まとめ買い</dt>
                              <dd className="">
                                0円<span className="taxin">(税込)</span>
                              </dd>
                            </span>
                            <div className="" style={{ display: "none" }}>
                              <dt>リピーター割引額</dt>
                              <dd className="">
                                0円<span className="taxin">(税込)</span>
                              </dd>
                            </div>
                            <dt>配送料金</dt>
                            <dd>
                              0円<span className="taxin">(税込)</span>
                            </dd>
                            <dd style={{ display: "none" }}></dd>
                            <span>
                              <dt style={{ display: "none" }}>
                                まとめ買い(送料割引)
                              </dt>
                              <dd
                                style={{ display: "none" }}
                                className=" discount"
                              >
                                0円<span className="taxin">(税込)</span>
                              </dd>
                            </span>
                          </dl>
                        </div>
                        <div>
                          <dl className="fee_table result">
                            <dt className="total">総合計</dt>
                            <dd className="total">
                              {calculateTotal()}円
                              <span className="taxin">(税込)</span>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="discount_attention">
                  <div className="flex_wrap">
                    <font _mstmutation="1">
                      ※定期購入をお選びいただいた場合、いつでも定期特別価格でお届けします。
                      <br _mstmutation="1" />
                      ※継続回数に限らず、いつでもお休みや解約が可能です。
                      <br _mstmutation="1" />
                      　次回お届け日の10日前までにお手続きください。
                    </font>
                    <br />
                    <ul>
                      <li>
                        休止をご希望の場合、マイページ「定期購入情報」から承ります。
                      </li>
                      <li>
                        解約をご希望の場合、お電話でのみ承ります。
                        <br />
                        0120-007-444　受付時間 9:00～18:00（年末年始などを除く）
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="btn_area move btmbtn">
                  <ul>
                    <li className="back">
                      <Link to="/menu" onClick={toTop}>
                        お買い物を続ける
                      </Link>
                    </li>
                    <li>
                      <span className="btn_receiver">
                        <button
                          className="check_button"
                          onClick={toCheckOut}
                          style={{ width: "100%" }}
                        >
                          ご購入手続きへ進む
                        </button>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CartPage;
