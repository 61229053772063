import React, { useContext, useState } from "react";
import Footer from "./Footer";
import "../css/shopList.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CartContext } from "./CartContext";
import Header from "./Header";
import productData from "../Datas/productData";

function ShopList() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { addToCart } = useContext(CartContext);
  const product = productData.find((item) => item.id === Number(id));

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [quantities, setQuantities] = useState(1);
  const [selectedColor, setSelectedColor] = useState(null);

  // 增加数量的函数
  const handleAdd = () => {
    setQuantities((prevQuantities) => Math.min(prevQuantities + 1, 10)); // 限制最大为 10
  };

  // 减少数量的函数
  const handleMinus = () => {
    setQuantities((prevQuantities) => Math.max(prevQuantities - 1, 0)); // 限制最小为 0
  };

  const selectLabel = (index) => {
    setSelectedIndex(index);
  };

  const selectColor = (color) => {
    setSelectedColor(color);
  };

  const calculateTotalPrice = () => {
    if (selectedIndex !== null) {
      const selectedRoute = product.routes[selectedIndex];
      return selectedRoute.price * quantities;
    }
    return 0;
  };

  const handleAddToCart = (id) => {
    if (product.colors.length > 0 && !selectedColor) {
      alert("色を選択してください!");
      return;
    }
    if (selectedIndex === null) {
      alert("商品はじゅうのカテゴリーを選択してください!");
      return;
    }
    const totalPrice = calculateTotalPrice();

    addToCart(id, totalPrice, quantities, selectedColor);
    navigate("/cartpage");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (!product) {
    return <div>商品が見つかりませんでした。</div>;
  }

  return (
    <div className="main-container">
      <Header />
      <div className="naturain_hm">
        <ul className="bread_link">
          <li>
            <Link to="/">TOP</Link>
          </li>
          <li>
            <Link to="/menu">商品一覧</Link>
          </li>
          <li>{product.nameWeight}</li>
        </ul>
        <div className="wrapper">
          <div className="buy_area">
            <article className="item_info">
              <div className="tags">{product.red}</div>
              <p className="product_lead">{product.name}</p>
              <h2>{product.nameWeight}</h2>
              <p className="product_spec">内容量：{product.ml}</p>
              <div className="image_area">
                <img src={product.images[1]} width="300" height="371" alt="" />
              </div>
            </article>

            <article className="item_course">
              <h3>ご希望のコースを選択のうえ、お好きな色をお選びください。</h3>
              <ul className="course_select">
                {product.routes.map((route, index) => (
                  <li key={index}>
                    <label
                      htmlFor="coursel"
                      style={{
                        border:
                          selectedIndex === index
                            ? "2px solid #cf0040"
                            : "1px solid #969696",
                      }}
                      onClick={() => selectLabel(index)}
                    >
                      <div>
                        <h4 className="type">{route.title}</h4>
                        <p className="price">
                          {route.price.toLocaleString()}円
                          <span className="taxin">(税込)</span>
                        </p>
                        {route.save && route.save.trim() !== "" && (
                          <div className="discount_item">
                            <p>
                              <strong>{route.save.toLocaleString()}</strong>円
                              <br />
                              お得<span className="super">※1</span>
                            </p>
                          </div>
                        )}

                        <ul className="option_tags">
                          {route.tip.map((tip, index) => (
                            <li key={index}>{tip}</li>
                          ))}
                        </ul>
                      </div>
                    </label>
                  </li>
                ))}
              </ul>
              {product.colors.length > 0 && (
                <div className="hm_color2">
                  <div className="select_box">
                    <ul className="color_list">
                      {product.colors.map((color, index) => (
                        <li className="color1" key={index}>
                          <label htmlFor="nthm1_1">
                            <span>{color.color}</span>
                            <br />
                            <div
                              className="bac_main_img"
                              style={{
                                backgroundImage: `url(${color.picture})`,
                                border:
                                  selectedColor === color
                                    ? "2px solid #cf0040"
                                    : "none",
                              }}
                              onClick={() => selectColor(color)}
                            ></div>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              <div className="add_cart">
                <div className="item_price">
                  <ul className="tags">
                    <li>送料無料</li>
                    <li>代引手数料無料</li>
                    <div>
                      <input
                        type="button"
                        value="－"
                        id="minus1"
                        className="minus"
                        _mstvalue="5941663"
                        onClick={handleMinus}
                      />
                      <input
                        name="matome"
                        type="text"
                        min="0"
                        max="10"
                        id="nthm3_1"
                        pid_teika="HM-MA-teika"
                        vid_teika="RBR"
                        className="quantity"
                        value={quantities}
                        readOnly
                      />
                      <input
                        type="button"
                        value="＋"
                        id="add1"
                        className="plus"
                        _mstvalue="3913"
                        onClick={handleAdd}
                      />
                    </div>
                  </ul>
                  <p className="price">
                    {calculateTotalPrice().toLocaleString()}円
                    <span className="taxin">（税込）</span>
                  </p>
                </div>
                <div className="btn_area">
                  <button
                    id="nextCartNormal3"
                    onClick={() => handleAddToCart(id)}
                  >
                    カートに入れる
                  </button>
                </div>
              </div>
            </article>
          </div>
        </div>

        <div className="item_feature">
          <div className="inner_feature">
            <div className="feature_inner">商品特長</div>
            <div className="feature_box">
              <table class="item_spec">
                <tbody>
                  {product.productCharacter.map(
                    (character) =>
                      character.use && (
                        <tr key={character.use[0]}>
                          <th>{character.use[0]}</th>
                          <td>{character.use[1]}</td>
                        </tr>
                      )
                  )}
                  {product.productCharacter.map(
                    (character) =>
                      character.idleTime && (
                        <tr key={character.idleTime[0]}>
                          <th>{character.idleTime[0]}</th>
                          <td>{character.idleTime[1]}</td>
                        </tr>
                      )
                  )}

                  {product.productCharacter.map(
                    (character) =>
                      character.aroma && (
                        <tr key={character.aroma[0]}>
                          <th>{character.aroma[0]}</th>
                          <td>{character.aroma[1]}</td>
                        </tr>
                      )
                  )}

                  {product.productCharacter.map(
                    (character) =>
                      character.patch && (
                        <tr key={character.patch[0]}>
                          <th>{character.patch[0]}</th>
                          <td>{character.patch[1]}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ShopList;
