const productData = [
  {
    id: 1,
    name: "一回でしっかりと白髪を染め上げハリとツヤのある美髪に",
    menuTxt: "ヘアマニキュア",
    red: "ヘアカラーリング",
    nameWeight: "ナチュライン ヘアマニキュア",
    ml: "150g",
    routes: [
      {
        id: 1,
        title: "お試し購入",
        price: "2010",
        tip: ["ブラシ付き", "初回限定"],
        save: "737",
      },
      {
        id: 2,
        title: "単品購入",
        price: "2340",
        tip: [],
        save: "",
      },
      {
        id: 3,
        title: "2本まとめ買い",
        price: "4680",
        tip: ["ブラシ付き", "送料・代引手数料無料※2"],
        save: "407",
      },
      {
        id: 4,
        title: "定期購入",
        price: "2010",
        tip: ["初回ブラシ付き", "送料・代引手数料無料※2"],
        save: "1257",
      },
    ],
    colors: [
      {
        id: 1,
        color: "ローズブラウン",
        picture:
          "	https://www.hoyu-direct.com/Contents/images/products/naturain_hm/color1.png",
      },
      {
        id: 2,
        color: "チェリーブラウン",
        picture:
          "	https://www.hoyu-direct.com/Contents/images/products/naturain_hm/color2.png",
      },
      {
        id: 3,
        color: "メープルブラウン",
        picture:
          "	https://www.hoyu-direct.com/Contents/images/products/naturain_hm/color3.png",
      },
      {
        id: 4,
        color: "ハニーブラウン",
        picture:
          "	https://www.hoyu-direct.com/Contents/images/products/naturain_hm/color4.png",
      },
      {
        id: 5,
        color: "クールブラウン",
        picture:
          "	https://www.hoyu-direct.com/Contents/images/products/naturain_hm/color5.png",
      },
      {
        id: 6,
        color: "カシスブラウン",
        picture:
          "	https://www.hoyu-direct.com/Contents/images/products/naturain_hm/color6.png",
      },
      {
        id: 7,
        color: "スーパーライトブラウン",
        picture:
          "	https://www.hoyu-direct.com/Contents/images/products/naturain_hm/color7.png",
      },
      {
        id: 8,
        color: "ライトブラウン",
        picture:
          "	https://www.hoyu-direct.com/Contents/images/products/naturain_hm/color8.png",
      },
      {
        id: 9,
        color: "ナチュラルブラウン",
        picture:
          "https://www.hoyu-direct.com/Contents/images/products/naturain_hm/color9.png",
      },
      {
        id: 10,
        color: "ダークブラウン",
        picture:
          "https://www.hoyu-direct.com/Contents/images/products/naturain_hm/color10.png",
      },
      {
        id: 11,
        color: "エナメルブラック",
        picture:
          "https://www.hoyu-direct.com/Contents/images/products/naturain_hm/color11.png",
      },
    ],
    productCharacter: [
      {
        use: ["使い切り目安", "ショートヘア（髪全体）約3回分 ※取り置き可"],
        idleTime: ["放置時間", "15分"],
        aroma: ["香り", "フローラル"],
        patch: ["パッチテスト", "不要"],
      },
    ],
    images: [
      "https://www.hoyu-direct.com/Contents/images/top/top_naturain_hm.jpg",
      "https://www.hoyu-direct.com/Contents/images/products/naturain_hm/product_img.png",
    ],
  },
  {
    id: 2,
    name: "頭皮と髪をいたわりながら、使うたびナチュラルに色づく",
    menuTxt: "カラートリートメント",
    red: "ヘアカラーリング",
    nameWeight: "ナチュライン カラートリートメント",
    ml: "240g",
    routes: [
      {
        id: 1,
        title: "単品購入",
        price: "3300",
        tip: [],
        save: "",
      },
      {
        id: 2,
        title: "まとめ買い(2本～)",
        price: "2930",
        tip: ["送料・代引手数料無料※2"],
        save: "370",
      },
      {
        id: 3,
        title: "定期購入(2本～)",
        price: "2615",
        tip: ["送料・代引手数料無料※2"],
        save: "685",
      },
    ],
    colors: [
      {
        id: 1,
        color: "ナチュラルブラウン",
        picture:
          "	https://www.hoyu-direct.com/Contents/images/products/naturain/color1.png",
      },
      {
        id: 2,
        color: "ダークブラウン",
        picture:
          "	https://www.hoyu-direct.com/Contents/images/products/naturain/color2.png",
      },
      {
        id: 3,
        color: "ナチュラルブラック",
        picture:
          "	https://www.hoyu-direct.com/Contents/images/products/naturain/color3.png",
      },
    ],
    productCharacter: [
      {
        use: [
          "使い切り目安",
          "ショートヘア(髪全体)で、使用条件を5回連続使用し、 その後週2～3回程度使用した場合、約10～15回分※取り置き可",
        ],
        idleTime: ["放置時間", "10分"],
        aroma: ["香り", "ほのかなフローラル"],
        patch: ["パッチテスト", "不要"],
      },
    ],
    images: [
      "	https://www.hoyu-direct.com/Contents/images/top/top_naturain_ct.jpg",
      "	https://www.hoyu-direct.com/Contents/images/products/naturain/product_img.png",
    ],
  },
  {
    id: 3,
    name: "2色パレットで自分色が作れるお出かけ前の簡単白髪隠し",
    menuTxt: "ヘアファンデーション",
    red: "ヘアカラーリング",
    nameWeight: "ナチュライン ヘアファンデーション",
    ml: "4g / 2ｇ×2色",
    routes: [
      {
        id: 1,
        title: "単品購入",
        price: "2750",
        tip: [],
        save: "",
      },
      {
        id: 2,
        title: "2個まとめ買い",
        price: "5090",
        tip: ["送料・代引手数料無料※2"],
        save: "410",
      },
    ],
    colors: [],
    productCharacter: [
      {
        use: ["使い切り目安", "顔回り・生え際約50回分"],
        aroma: ["香り", "無香料"],
      },
    ],
    images: [
      "	https://www.hoyu-direct.com/Contents/images/top/top_naturain_fd.jpg",
      "	https://www.hoyu-direct.com/Contents/images/products/naturain_fand/product_img.png",
    ],
  },
  {
    id: 4,
    name: "黒髪を、あきらめない聖なるハーブ「ヤーバサンタ※」の抽出エキスを配合した、頭皮用ローション",
    menuTxt: "ビゲン スキャルプエッセンス",
    red: "ヘアケア / 頭皮ケア",
    nameWeight: "ビゲン スキャルプエッセンス",
    ml: "120ml",
    routes: [
      {
        id: 1,
        title: "お試し購入",
        price: "5900",
        tip: ["初回限定"],
        save: "1000",
      },
      {
        id: 2,
        title: "単品購入",
        price: "6900",
        tip: [],
        save: " ",
      },
      {
        id: 3,
        title: "まとめ買い",
        price: "12420",
        tip: ["送料・代引手数料無料※2"],
        save: "690",
      },
      {
        id: 4,
        title: "定期購入",
        price: "5900",
        tip: ["送料・代引手数料無料※2"],
        save: "1000",
      },
    ],
    colors: [],
    productCharacter: [
      {
        use: [
          "特長",
          "聖なるハーブ「ヤーバサンタ」のエキスを配合した頭皮用ローション",
        ],
        idleTime: ["使い切り目安", "約1ヶ月間（1日2回 20プッシュ程度の使用）"],
        aroma: [
          "香り",
          "ヤーバサンタのハーブ本来の香りに、ラベンダー油を加えたリラックスできる香り",
        ],
      },
    ],
    images: [
      "	https://www.hoyu-direct.com/Contents/images/top/slider_bigen_ess.png",
      "	https://www.hoyu-direct.com/Contents/images/products/bigen_scalpessence/product_img.png",
    ],
  },
  {
    id: 5,
    name: "2つのクリームで２つの効果染まりと色持ちダブルでイン",
    menuTxt: "シエロ ダブルインカラートリートメント",
    red: "ヘアカラーリング",
    nameWeight: "シエロダブルインカラートリートメント",
    ml: "110ｇ（55g+55g）",
    routes: [
      {
        id: 1,
        title: "単品購入",
        price: "3820",
        tip: ["送料無料※2"],
        save: "",
      },
      {
        id: 2,
        title: "2本まとめ買い",
        price: "6300",
        tip: ["送料・代引手数料無料※2"],
        save: "670",
      },
      {
        id: 3,
        title: "定期購入",
        price: "3000",
        tip: ["送料・代引手数料無料※2"],
        save: "820",
      },
    ],
    colors: [
      {
        id: 1,
        color: "ナチュラルブラック",
        picture:
          "https://www.hoyu-direct.com/Contents/images/products/naturain/color3.png",
      },
      {
        id: 2,
        color: "ダークブラウン",
        picture:
          "		https://www.hoyu-direct.com/Contents/images/products/naturain/color2.png",
      },
    ],
    productCharacter: [
      {
        use: [
          "使い切り目安",
          "約1ヶ月※生え際と分け目への使用※使い始めに3日間、その後週1回の使用",
        ],
        idleTime: ["放置時間", "5分～10分"],
        aroma: ["香り", "無香料"],
        patch: ["パッチテスト", "不要"],
      },
    ],
    images: [
      "	https://www.hoyu-direct.com/Contents/images/top/slider_cielo_cdi.jpg",
      "https://www.hoyu-direct.com/Contents/images/products/cielo_cdi/product_img.png",
    ],
  },
];

export default productData;
