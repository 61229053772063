import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="base">
          <div className="foot_top_img_main">
            <img
              width="90"
              height="59"
              alt=""
              src="https://www.hoyu.co.jp/assets/img/common/icon_company_logo_white.svg"
            />
          </div>
          <div className="red_ul_div">
            <ul>
              <li>
                <Link to="/contact">お問い合わせ</Link>
              </li>
              <li>
                <Link to="/terms">利用規約</Link>
              </li>
              <li>
                <Link to="/privacy">個人情報保護方針</Link>
              </li>
            </ul>
          </div>
          <p className="copy_p">
            Copyright Hoyu Co., Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
