import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./Component/Main";
import { CartProvider } from "./Component/CartContext";
import Menu from "./Component/Menu";
import SuccessPay from "./Component/SuccessPay";
import ShopList from "./Component/ShopList";
import CheckOut from "./Component/CheckOut";
import Contact from "./Component/Contact";
import CartPage from "./Component/CartPage";

function App() {
  return (
    <CartProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/shopList/:id" element={<ShopList />} />
          <Route path="/cartpage" element={<CartPage />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/paysuccess" element={<SuccessPay />} />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
