import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import { Link } from "react-router-dom";
// import newsData from "../Datas/newsData";

function Main() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="transparent-div">
          <div className="block-mv04_txt">
            <img
              style={{ width: "100%" }}
              alt=""
              src="https://www.hoyu.co.jp/uploads/images/mv_02_pc.jpg"
            />
          </div>
        </div>

        <div className="p-top-mv__slider">
          <div className="l-container">
            <h2 className="p-top-story__title js-effect-fade c-effect-fade c-effect-scroll story_h2">
              <img
                src="https://www.hoyu.co.jp/assets/img/top/img_hoyu_story.svg"
                alt="hoyu story"
                width="460"
                height="90"
                className="p-top-story__title-img"
              />
            </h2>
            <p className="p-top-story__text-white js-effect-fade c-effect-fade c-effect-scroll">
              わたしたちhoyuは、
              <br className="max-sp--lg" />
              「COLOR YOUR HEART」をスローガンに、
              <br />
              世界中の人々の心に彩りを提供するため、
              <br className="max-sp--lg" />
              挑戦し、進み続けます
            </p>
            <div className="story_div_all">
              <div className="story_inner_div">
                <img
                  width="540"
                  height="360"
                  alt=""
                  src="https://www.hoyu.co.jp/uploads/img_000869_mv.webp"
                />
                <p className="p-top-story__slider-title">
                  「“三方良し”の視点でサロン業界とホーユーをつなぐ架け橋」 COLOR
                  creators Vol.4 藤戸彩圭
                </p>
              </div>
              <div className="story_inner_div">
                <img
                  width="540"
                  height="360"
                  alt=""
                  src="https://www.hoyu.co.jp/uploads/img_000773_mv.webp"
                />
                <p className="p-top-story__slider-title">
                  「さまざまな商品の製剤を手がけ続けるレシピ開発者」 COLOR
                  creators Vol.2 楠見 真実
                </p>
              </div>
              <div className="story_inner_div">
                <img
                  width="540"
                  height="360"
                  alt=""
                  src="https://www.hoyu.co.jp/uploads/img_000656_mv.webp"
                />
                <p className="p-top-story__slider-title">
                  「ヘアカラー剤のあり方に新風を吹き込んだ先駆者」COLOR creators
                  Vol.1 野村 卓央
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="about_absolute_div">
          <div className="about_absolute_grid">
            <div className="about_grid_inner">
              <p className="c-head__sub--en is-red" lang="en">
                ABOUT
              </p>
              <h2 className="c-head__h2--lg is-red">hoyuについて</h2>
              <p className="p-top-about__read-text js-effect-fade c-effect-fade c-effect-scroll">
                hoyuは、ヘアカラーを通じて
                <br />
                世界の皆さまに「心に彩りを」届けます。
              </p>
            </div>
            <div className="about_grid_inner">
              <p className="p-top-about__text">
                ヘアカラーを通じて新たな喜びや幸せを世界中の人にお届けしたい。
                <br />
                そう願いながら、私たちは今日まで成長を続けてきました。
                <br />
                これまでも、これからも、世界のヘアファッション文化の
                <br />
                発展のために、貢献し、創造し続けていくこと。
                <br />
                それが私たちの使命です。
              </p>
              <Link to="/about" className="c-button__link--form is-short">
                <span className="c-button__label--red">会社情報TOP</span>
              </Link>
            </div>
            <div className="about_grid_inner">
              <img
                width="575"
                height="356"
                alt=""
                src="https://www.hoyu.co.jp/assets/img/top/img_about_01.webp"
              />
              <div className="p-top-about__on">
                <p className="p-top-about__on-text">ごあいさつ</p>
              </div>
            </div>
            <div className="about_grid_inner">
              <img
                width="575"
                height="356"
                alt=""
                src="https://www.hoyu.co.jp/assets/img/top/img_about_02.webp"
              />
              <div className="p-top-about__on1">
                <p className="p-top-about__on-text">
                  イキイキ・ワクワクビジョン2030
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="p-top-sustainability">
          <div className="p-top-sustainability__container l-container1">
            <div className="l-head-row js-effect-fade c-effect-fade c-effect-scroll">
              <div className="c-head__group">
                <p className="c-head__sub--en is-red" lang="en">
                  SUSTAINABILITY
                </p>
                <h2 className="c-head__h2 is-red">サステナビリティ</h2>
              </div>
              <p className="c-button pc-only u-sm-display--none">
                <Link
                  to="/sustainability/"
                  className="c-button__link--form is-short"
                >
                  <span className="c-button__label--red">
                    サステナビリティTOP
                  </span>
                </Link>
              </p>
            </div>
            <p className="p-top-sustainability__head-text js-effect-fade c-effect-fade c-effect-scroll">
              地球にも、人にも、
              <br className="sp-only" />
              ずっとやさしいものづくりを。
            </p>
            <div className="p-top-sustainability__content-wrapper js-effect-fade c-effect-fade c-effect-scroll grid_main_div">
              <div className="l-media-link">
                <ul className="l-row--2">
                  <li className="l-col--2-grid">
                    <p className="c-media">
                      <img
                        src="	https://www.hoyu.co.jp/sustainability/uploads/img_sustainability_business.webp"
                        alt="安心・安全への取り組み"
                        loading="lazy"
                        className="c-media__img--full"
                      />
                    </p>
                    <dl className="l-media-link__defination">
                      <dt className="l-media-link__defination-title">
                        安心・安全への取り組み
                      </dt>
                      <dd className="l-media-link__defination-text">
                        より安全にお使いいただける製品を提供するために。
                      </dd>
                    </dl>
                  </li>
                  <li className="l-col--2-grid">
                    <p className="c-media">
                      <img
                        src="https://www.hoyu.co.jp/sustainability/uploads/img_sustainability_employee.webp"
                        alt="人材・労働環境への取り組み"
                        loading="lazy"
                        className="c-media__img--full"
                      />
                    </p>
                    <dl className="l-media-link__defination">
                      <dt className="l-media-link__defination-title">
                        人材・労働環境への取り組み
                      </dt>
                      <dd className="l-media-link__defination-text">
                        一人ひとりの従業員がより安全安心に、やりがいを持って働けるように。
                      </dd>
                    </dl>
                  </li>
                  <li className="l-col--2-grid">
                    <p className="c-media">
                      <img
                        src="	https://www.hoyu.co.jp/sustainability/uploads/img_sustainability_environment.webp"
                        alt="地球環境への取り組み"
                        loading="lazy"
                        className="c-media__img--full"
                      />
                    </p>
                    <dl className="l-media-link__defination">
                      <dt className="l-media-link__defination-title">
                        地球環境への取り組み
                      </dt>
                      <dd className="l-media-link__defination-text">
                        大切な地球環境を守り、より豊かな地球を次世代につなげるために。
                      </dd>
                    </dl>
                  </li>
                  <li className="l-col--2-grid">
                    <p className="c-media">
                      <img
                        src="https://www.hoyu.co.jp/sustainability/uploads/img_sustainability_health_management.webp"
                        alt="健康経営への取り組み"
                        loading="lazy"
                        className="c-media__img--full"
                      />
                    </p>
                    <dl className="l-media-link__defination">
                      <dt className="l-media-link__defination-title">
                        健康経営への取り組み
                      </dt>
                      <dd className="l-media-link__defination-text">
                        社員が心身ともに健康で、イキイキと働けるように。
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
            <div className="p-top-sustainability__btn-wrapper sp-only">
              <p className="c-button">
                <Link to="/" className="c-button__link--form is-short">
                  <span className="c-button__label--red">
                    サステナビリティTOP
                  </span>
                </Link>
              </p>
            </div>
          </div>
        </div>

        <section className="p-top-development js-effect-fade c-effect-fade c-effect-scroll">
          <div className="p-top-development__container l-container2">
            <div className="p-top-development__content">
              <div className="p-top-development__detail">
                <hgroup className="c-head__group">
                  <p className="c-head__sub--en is-red" lang="en">
                    RESEARCH&amp;DEVELOPMENT
                  </p>
                  <h2 className="c-head__h2 is-red">研究開発</h2>
                </hgroup>
                <p className="p-top-development__media sp-only">
                  <img
                    src="https://www.hoyu.co.jp/assets/img/top/img_development_sp.webp"
                    alt=""
                    width="780"
                    height="630"
                    className="p-top-development__img"
                  />
                </p>
                <p className="p-top-development__text c-text__normal-md-regular">
                  髪質や体質にかかわらず、
                  <br />
                  誰でも安心してヘアライフを楽しめる
                  <br className="max-sp--lg" />
                  商品をつくるために
                  <br />
                  アレルギー研究などの分野で
                  <br className="max-sp--lg" />
                  業界をリードしています。
                </p>
                <div className="p-top-development__button c-button-wrapper">
                  <p className="c-button">
                    <Link
                      to="/"
                      className="c-button__link--form is-short"
                      style={{ textAlign: "center" }}
                    >
                      <span className="c-button__label--red">研究開発TOP</span>
                    </Link>
                  </p>
                </div>
              </div>
              <p className="p-top-development__media pc-only">
                <img
                  src="https://www.hoyu.co.jp/assets/img/top/img_development.webp"
                  alt=""
                  width="780"
                  height="630"
                  className="p-top-development__img"
                />
              </p>
            </div>
          </div>
        </section>

        <div className="p-top-links js-effect-fade c-effect-fade c-effect-scroll">
          <div className="p-top-links__item">
            <p className="p-top-links__media">
              <img
                src="https://www.hoyu.co.jp/assets/img/top/bg_recrite.jpg"
                alt="採用情報"
                className="p-top-links__media-img"
                loading="lazy"
              />
            </p>
            <div className="p-top-links__title">
              <p className="p-top-links__title--en">RECRUITMENT</p>
              <p className="p-top-links__title--ja">採用情報</p>
            </div>
          </div>
          <div className="p-top-links__item">
            <p className="p-top-links__media">
              <img
                src="	https://www.hoyu.co.jp/assets/img/top/bg_faq.jpg"
                alt="お客様相談室"
                className="p-top-links__media-img"
                loading="lazy"
              />
            </p>
            <div className="p-top-links__title1">
              <p className="p-top-links__title--en">FAQ</p>
              <p className="p-top-links__title--ja">お客様相談室</p>
            </div>
          </div>
        </div>

        <div className="three_border_div">
          <div className="border_grid_div">
            <div className="border_inner">
              <div className="border_img">
                <img
                  alt=""
                  src="	https://www.hoyu.co.jp/uploads/images/museum.jpg"
                />
              </div>
              <dl className="p-top-external__slider-title">
                <dt className="p-top-external__slider-title-text">
                  ホーユーヘアカラーミュージアム
                </dt>
                <dd className="p-top-external__slider-title-sub">
                  創立100周年を記念して、ホーユーヘアカラーミュージアムを開館
                </dd>
              </dl>
            </div>
            <div className="border_inner">
              <div className="border_img">
                <img
                  alt=""
                  src="	https://www.hoyu.co.jp/uploads/images/LICOLO_logo1.png"
                />
              </div>
              <dl className="p-top-external__slider-title">
                <dt className="p-top-external__slider-title-text">
                  美髪情報サイト　LICOLO
                </dt>
                <dd className="p-top-external__slider-title-sub">
                  「髪から自分を好きになる」髪に関する最新情報をお届け！
                </dd>
              </dl>
            </div>
            <div className="border_inner">
              <div className="border_img">
                <img
                  alt=""
                  src="https://www.hoyu.co.jp/uploads/images/allergy_01.jpg"
                />
              </div>
              <dl className="p-top-external__slider-title">
                <dt className="p-top-external__slider-title-text">
                  ホーユーのアレルギー受託解析サービス
                </dt>
                <dd className="p-top-external__slider-title-sub">
                  最新のアレルギー研究成果を臨床現場にフィードバックしています
                </dd>
              </dl>
            </div>
          </div>
        </div>

        <div className="public_div_all">
          <div className="public_inner">
            <h2>公式通販サイト</h2>
            <div className="public_img">
              <img
                alt=""
                src="https://www.hoyu.co.jp/assets/img/top/img_external_banner_01.webp"
              />
              <img
                alt=""
                src="https://www.hoyu.co.jp/assets/img/top/img_external_banner_03.webp"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
